import styles from './index.module.scss';

import { createComponent } from '@lib/util/templateHelpers';
import shared from '@lib/components';
const { 
  Flex
} = shared;

const carouselBlockStates = [
  'featured'
];

export default createComponent('CarouselBlock', { styles, classStates: carouselBlockStates }, function CarouselBlock ({ className, slots }, props) {
  return (
    <div className={`Block ${className}`} data-id={props.id || ''} data-ready={props.ready}>
      <Flex className='CarouselBlock__Title' container gap0>
        <Flex>{slots.title}</Flex>
      </Flex>
      <div className='CarouselBlock__Content'>
        <div className='CarouselBlock__ContentInner'>
          {slots.defaultSlot}
        </div>
      </div>
    </div>
  )
});
