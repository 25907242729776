// Third-party
import Head from 'next/head';
import dynamic from 'next/dynamic';
import React, { useState, useRef, useEffect } from 'react';

// First-party
import { Slot, If, For, createPage } from '@lib/util/templateHelpers';
import rating from '@lib/util/rating';
// Component lib
import shared from '@lib/components';

const { 
  Block,
  Box,
  RouterLink,
  Content,
  Title,
  DocumentTitle
} = shared;

// Local
import styles from './index.module.scss';
import gameApi from '@/apis/drupal/game';
import GameTileLayout from '@/layouts/GameTileLayout';

// Local components
import CarouselBlock from '@/components/CarouselBlock';
import IoGameTile from '@/components/IoGameTile';
const Carousel = dynamic(() => import('@/components/Carousel'));
const AllGamesBlock = dynamic(() => import('@/components/AllGamesBlock'));

const GameCarousel = (props) => {
  const [ games, setGames ] = useState(props.games);
  const [ gamesFormatted, setGamesFormatted ] = useState([]);

  useEffect(() => {
    if (!(props.featured && props.stickiedGames)) return;

    const formatted = [];

    let i = 0;
    let gameIndex = 0;
    let stickyIndex = 0;

    while (gameIndex < games.length) {
      // Featured game (big)
      if (i % 5 === 0) {
        formatted.push(games[ gameIndex++ ]);
      }

      // Stickied game (small)
      else {
        formatted.push(props.stickiedGames[ stickyIndex++ % props.stickiedGames.length ]);
      }

      i++;
    }

    setGamesFormatted(formatted);
  }, [ games ]);

  const fetchMoreGames = async (nextLimit, nextIndex) => {
    return await props.fetchMoreGames(nextLimit, nextIndex);
  };

  const setNextGames = async (nextGames) => {
    const filteredGames = nextGames.filter((game) => {
      return !games.find((g) => (g.path === game.path));
    });
    setGames((currentGames) => currentGames.concat(props.featured ? nextGames : filteredGames));
  };

  const transition = {
    easing: 'spring(1, 100, 20, 0)'
  };
  
  const totalCount = props.games.length 
    ? props.games[0].totalCount + (
        props.stickiedGames && props.stickiedGames[0]
          ? props.stickiedGames[0].totalCount
          : 0
      )
    : 0;

  const hasFetchedMoreOnLoadRef = useRef(false);

  useEffect(() => {
    if (!props.fetchMoreOnLoad) return;
    if (hasFetchedMoreOnLoadRef.current) return;

    hasFetchedMoreOnLoadRef.current = true;

    const fetchMore = async () => {
      const tileWidth = 178 + 8; // width + gap
      const numTilesToLoad = Math.max(
        Math.ceil(window.innerWidth / tileWidth) - props.games.length,
        4
      );
      const nextGames = await props.fetchMoreGames(numTilesToLoad, props.games.length);
      setGames(prevGames => [ ...prevGames, ...nextGames ]);
    };
    fetchMore();
  }, [ props.fetchMoreOnLoad ])

  return (
    <Carousel
      initialIndex={props.games.length}
      fetchMoreGames={fetchMoreGames}
      setNextGames={setNextGames}
      totalGames={totalCount}
      transition={transition}
      featured={props.featured}
    >
      {
        If(props.featured && props.stickiedGames, () => (
          <>
            {
              For(gamesFormatted, (game, index) => (
                <IoGameTile
                  key={`${index}__${game.id || 'lastGame'}`}
                  game={game}
                  className={index < props.games.length && '--finished'}
                  featured={index % 5 === 0}
                  priority={props.priority && index <= 8}
                />
              ))
            }
          </>
        ))
        .Else(() => (
          <>
            {
              For(games, (game, index) => (
                <IoGameTile
                  key={`${index}__${game.id || 'lastGame'}`}
                  game={game}
                  className={index < props.games.length && '--finished'}
                  priority={props.priority && index <= 8}
                />
              ))
            }
          </>
        ))
        .EndIf()
      }
    </Carousel>
  );
}

export async function getServerSideProps () {
  const featuredGames = await gameApi.listFeaturedOrSticky(2);
  const stickiedGames = await gameApi.listSticky(4);
  const newGames = await gameApi.listNew(4);
  const popularGames = await gameApi.listPopularAlt(4);

  return {
    props: {
      featuredGames,
      stickiedGames,
      newGames,
      popularGames,
    },
  }
}

export default createPage('IndexPage', { styles }, function IndexPage ({ className }, props) {
  const [ categories, setCategories ] = useState({});
  const [ allGames, setAllGames ] = useState(null);
  const carouselRef = useRef(null);
  const fetchingAllGamesRef = useRef(false);

  useEffect(() => {
    const fetchCategory = async (id, el) => {
      const category = process.env.APP_CONFIG.HOME_PAGE_CATEGORY_CAROUSELS.find((cat) => cat.id === id);

      setCategories((curCategories) => { 
        return {
          ...curCategories,
          [id]: {
            ...category
          },
        }
      });
    };

    const fetchAllGames = async () => {
      if (fetchingAllGamesRef.current) return;

      fetchingAllGamesRef.current = true;

      const nextGames = await gameApi.listAll(12);
      setAllGames(nextGames);
    };

    const handler = () => {
      const currentY = window.scrollY + window.innerHeight;
      const currentHeight = window.innerHeight;

      Array.from(carouselRef.current.querySelectorAll('.CarouselBlock')).forEach((el) => {
        const id = el.getAttribute('data-id');
        const ready = el.getAttribute('data-ready') === 'true';

        if (ready) return;

        const brect = el.getBoundingClientRect();
        const targetY = brect.y;

        if (currentY >= targetY || currentHeight >= targetY) {
          el.setAttribute('data-ready', true);
          fetchCategory(id, el);
        }
      });

      {
        const el = carouselRef.current.querySelector('.AllGamesBlock');
        if (!el) return;

        const brect = el.getBoundingClientRect();
        const targetY = brect.y;

        if (currentY >= targetY || currentHeight >= targetY) {
          fetchAllGames();
        }
      }
    };
    document.addEventListener('scroll', handler);
    handler();

    return () => {
      document.removeEventListener('scroll', handler)
    }
  }, []);

  const fetchMoreFeaturedGames = async (limit, index) => {
    const result = await gameApi.listFeaturedOrSticky(limit, index);
    return result.filter((game) => game !== undefined);
  };

  const fetchMoreNewGames = async (limit, index) => {
    return await gameApi.listNew(limit, index);
  };

  const fetchMorePopularGames = async (limit, index) => {
    return await gameApi.listPopularAlt(limit, index);
  };

  const fetchMoreAllGames = async (limit, index) => {
    return await gameApi.listAll(limit, index);
  };

  const fetchMoreCategoryGames = async (id, limit, index) => {
    return await gameApi.listByCategory(id, limit, index);
  };

  return (
    <GameTileLayout className={className}>
      <DocumentTitle />

      <Head>
        <script 
          type='application/ld+json' 
          dangerouslySetInnerHTML={{ 
            __html: JSON.stringify({
              '@context': 'https://schema.org/',
              '@type': 'WebSite',
              'name': process.env.APP_CONFIG.DEFAULT_TITLE,
              'url': process.env.APP_CONFIG.URL + '/',
                potentialAction: {
                  '@type': 'SearchAction',
                  'target': `${process.env.APP_CONFIG.SEARCH_URL}{search_term_string}`,
                  'query-input': 'required name=search_term_string'
                }
            }, null, 2)
          }}
        />
        <script 
          type='application/ld+json' 
          dangerouslySetInnerHTML={{ 
            __html: JSON.stringify({
              '@context': 'https://schema.org',
              '@type': 'Organization',
              'name': process.env.APP_CONFIG.ORGANIZATION.NAME,
              'url': process.env.APP_CONFIG.ORGANIZATION.URL,
              'logo': process.env.APP_CONFIG.ORGANIZATION.LOGO,
              'sameAs': [
                ...(process.env.APP_CONFIG.ORGANIZATION.SOCIAL_LINKS || [])
              ]
            }, null, 2)
          }}
        />
        <script 
          type='application/ld+json' 
          dangerouslySetInnerHTML={{ 
            __html: JSON.stringify({
              '@type': 'ItemList',
              'itemListElement': props.popularGames.map((game, index) => ({
                '@type': 'ListItem',
                'position': index + 1,
                'url': game.path,
                'item': {
                  '@context': 'https://schema.org',
                  '@type': 'VideoGame',
                  'name': game.title,
                  'url': game.path,
                  'image': game.thumbnailUrl,
                  'description': game.descriptionSimple,
                  'inLanguage': [ 'English' ],
                  'aggregateRating':{
                    '@type': 'AggregateRating',
                    'ratingValue': rating(game.rating),
                    'ratingCount': game.totalVotes
                  },
                  'genre': process.env.APP_CONFIG.HOME_PAGE_CATEGORY_CAROUSELS.map((category) => category.name),
                  'gamePlatform': [ 'HTML5' ]
                }
              }))
            }, null, 2)
          }}
        />
      </Head>

      {/* Featured games */}
      <CarouselBlock featured>
        <Slot name='title'><h2>Featured io Games</h2></Slot>
        <GameCarousel 
          featured
          games={props.featuredGames} 
          stickiedGames={props.stickiedGames}
          fetchMoreGames={fetchMoreFeaturedGames}
          fetchMoreOnLoad
          priority={true}
        />
      </CarouselBlock>
      
      {/* New games */}
      <CarouselBlock>
        <Slot name='title'><h2>New io Games</h2></Slot>
        <GameCarousel 
          games={props.newGames} 
          fetchMoreGames={fetchMoreNewGames}
          fetchMoreOnLoad
          priority={true}
        />
      </CarouselBlock>

      {/* Popular games */}
      <CarouselBlock>
        <Slot name='title'><h2>Popular io Games</h2></Slot>
        <GameCarousel 
          games={props.popularGames}
          fetchMoreGames={fetchMorePopularGames} 
          fetchMoreOnLoad
          priority={true}
        />
      </CarouselBlock>

      <div className='Block' ref={carouselRef}>
        {/* Additional categories */}
        {
          For(process.env.APP_CONFIG.HOME_PAGE_CATEGORY_CAROUSELS, (category, index) => (
            <CarouselBlock 
              key={category.id} 
              id={category.id}
              ready={categories[ category.id ] !== undefined}
            >
              <Slot name='title'><h2>{category.name}</h2></Slot>
              {
                If(categories[ category.id ], () => (
                  <GameCarousel
                    key={`${category.id}_carousel`}
                    games={[]} 
                    fetchMoreGames={(nextIndex, limit) => fetchMoreCategoryGames(categories[ category.id ].id, nextIndex, limit)} 
                    fetchMoreOnLoad
                  />
                ))
                  .Else(() => (
                    <GameCarousel 
                      key={`${category.id}_skeleton`}
                      games={[]} 
                      fetchMoreGames={() => []} 
                    />
                  ))
                  .EndIf()
              }
            </CarouselBlock>
          ))
        }
      
        {/* All games */}
        {
          If(allGames && allGames.length > 0, () => (
            <AllGamesBlock key='AllGamesBlock' games={allGames} fetchMoreGames={fetchMoreAllGames} center>
              <Slot name='title'>All io Games</Slot>
            </AllGamesBlock>
          ))
            .Else(() => (
              <div key='AllGamesBlock_skeleton' className='AllGamesBlock' />
            ))
            .EndIf()
        }
      </div>

      {
        If(allGames && allGames.length > 0, () => (
          <Block>
            <Box className='IndexPage__BottomBox'>
              <Title>IO Games List</Title>
              <Content>
                <p>Play the newest io games like <RouterLink href="/taming-io">taming.io</RouterLink> and popular titles like <RouterLink href="/krunker-io">Krunker.io</RouterLink> and <RouterLink href="/skribbl-io">Skribbl.io</RouterLink>. Games are sorted by the number of plays they get here on iogames.space in the last week. You can also sort by <RouterLink href="/top-rated">top rated</RouterLink> or <RouterLink href="/new">newest io games</RouterLink>.</p>
                <p>IO Games are a genre of free realtime multiplayer online games that you can play in your browser without needing to install anything or create an account. The first io game was agar.io. IO is a domain extension which stands for "Indian Ocean" but it is a favored domain extension by game developers because it also stands for "in / out."</p>
                <Title h2 size4>History of IO Games</Title>
                <p>The io game genre all began with the release of an insanely addicting game called agar.io (which was probably inspired by another game called “Osmos”). Agario grew so fast that within months, it was already seeing several hundred thousand players worldwide. What made this io game so unique was it’s a very interesting style. Unlike most browser games of the past, it was designed to be played in a full-screen browser window and was entirely multiplayer. This set the framework for many of the other .io games that would hit the web in the months to come.</p>
                <Title h2 size4>New IO Games, Rules for Success</Title>
                <p>Most fun and successful new io games like <RouterLink href="/zombs-io">zombs.io</RouterLink>, <RouterLink href="/zombsroyale-io">zombsroyale.io,</RouterLink>&nbsp;<RouterLink href="/slither-io">slither.io</RouterLink>, <RouterLink href="/splix-io">splix.io</RouterLink>, and <RouterLink href="/moomoo-io">moomoo.io</RouterLink> follow these general guidelines:</p>
                <ul>
                <li>Have the player be able to hop right into a game with one click.</li>
                <li>Keep it super simple to learn but difficult to master, like with the <RouterLink href="/krunker-io">krunker io game</RouterLink>.</li>
                <li>Allow the player to “scale” their authority in-game compared to other players. (Ex. in agar.io you grow a lot bigger and scarier the more you eat and scale your size.)</li>
                <li>And last but not least, they are either competitive or cooperative.</li>
                <li>Slitherio is a good example. It's a very simple game, with easy-to-use controls. There's no experience required to begin playing, meaning people can get the hang of it very quickly. Despite this, the game is difficult to master. The simple, clean graphics are also worth mentioning as a relevant characteristic of the genre.</li>
                </ul>
                <Title h2 size4>Why Create the List of Top IO Games?</Title>
                <p>After I found the first few popular <RouterLink href="/">io games</RouterLink>, I tried to find more. Sadly, without a list, they were really hard to find. I felt that they deserved more exposure, so I decided to create my own list. I put together all the titles I could find, and many helpful users shared new ones with me. From that point on, I was on a mission to push the io movement forward!</p>
                <p>On this site, you will find that the games in the list have a common style and theme similar to those mentioned above. The goal of the list is to bring exposure to these awesome .io games that oftentimes go unnoticed. This is due to the fact that they are all standalone games which means that they are all hosted on separate sites. That’s what I'm here for! So, support the games and make sure you bookmark this site. (CTRL-D) I try to keep it updated with the best, new io games every day, so make sure to check back from time to time. Have fun!</p>
              </Content>
            </Box>
          </Block>
        )).EndIf()
      }
    </GameTileLayout>
  )
});
